<template>
  <div class="bigBj" id="eng_hyc">
    <!-- <img src="../assets/image/2025.png" class="centerImg" /> -->
    <el-carousel indicator-position="outside">
      <el-carousel-item v-for="item in imgList" :key="item.id">
        <img :src="item.imgUrl" alt="" />
      </el-carousel-item>
    </el-carousel>
    <div class="contentBox">
      <div class="content2">
        <div class="centerTxt" style="margin-top:65px;">
          <img :src="imgGaoUrl" width="315" height="314" />
          <p>Convener</p>
          <p>Dr. Junping Gao</p>
        </div>
        <div>
          <div class="title2">
            <h3>WELCOME</h3>
          </div>
          <p>
            On behalf of the organizing committee of the 9th International Rose
            Conference, I warmly invite you to join us for this significant
            event, which will take place from May 10th to 15th, 2025, in
            Mentougou District, Beijing, China.
          </p>
          <p>
            China, one of the birthplaces of rose cultivation, has a rich
            history of rose breeding. As early as the Song Dynasty, there were
            illustrated catalogs of rose varieties. Some of the famous ancient
            Chinese rose cultivars, such as ‘Yue Yue Fen’ (also named R.
            chinensis ‘Old Blush’) and ‘Fo Jian Xiao’ (R. odorata ‘Buddha’s
            Smile’), are known for their recurrent blooming, unique tea-scent
            fragrance, and high-centered flower shape. Additionally, China is
            home to several wild rose species, such as Rosa chinensis var.
            spontanea and R. odorata var. gigantea, which have been vital in
            breeding programs for modern roses due to their diverse genetic
            traits. The influence of China and its roses on the world of
            horticulture is profound. Modern roses owe much to the ancient
            Chinese gardeners who, through their dedication and selective
            breeding, achieved the nearly continuous flowering characteristic
            that rose enthusiasts cherish today. Today, China produces nearly
            one-third of the world's roses, highlighting its importance in the
            global market.
          </p>
          <p>
            The primary aim of the conference is to advance technological
            innovation and industrial development, foster the construction of
            garden cities and comprehensive forest cities, and support rural
            revitalization, industry prosperity, and the establishment of an
            international hub for rose breeding. We look forward to welcoming
            you to Mentougou District, Beijing, to celebrate the beauty and
            diversity of roses and horticultural science. Let us explore new
            horizons in rose cultivation and share insights that will shape the
            future of the industry.
          </p>
          <p>See you in Beijing in May 2025!</p>
          <p class="rightTxt">Junping Gao &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p class="rightTxt">for the organizing committee</p>
          

        </div>
      </div>
      <!-- <div class="content">
        <div class="txt">
          <p>
            The International Society for Horticultural Science (ISHS), along with the Organizing Committee of the IX International Symposium on Rose Research and Cultivation, extends a heartfelt invitation to you to be a part of the most prestigious international symposium dedicated to roses. The symposium, slated to commence on May 10th and conclude on May 15th, 2025, will take place in the vibrant city of Beijing, China.
This momentous event aims to highlight the remarkable progress of China's rose industry while also serving as aforum for scientists, students, rose farmers, and other professionals engaged in rose scientific research and production. It offers an excellent opportunity for them to converge, engage in meaningful discussions, and share the latest scientific and technological advancements, industrial trends, and seek avenues for international collaboration.
          </p>
          <p>
            We eagerly anticipate your participation and the valuable contributions you will bring to this esteemed symposium.
          </p>
         
          <p>The main topics:</p>
          <div class="left30">
            S1: Genetics and Genomics<br>
            S2: Breeding, Social Sciences and Humanities<br>
            S3: Physiology<br>
            S4: Secondary Metabolites<br>
            S5: Biotic and Abiotic stress <br>
            S6: Postharvest Technologies and Processing<br>
          </div>
          <p>Looking forward to your visit and participation!</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        {
          id: "banner1",
          imgUrl: require("@/assets/image/banner1.jpg"),
        },
        {
          id: "banner2",
          imgUrl: require("@/assets/image/banner2.jpg"),
        },
        {
          id: "banner3",
          imgUrl: require("@/assets/image/banner3.jpg"),
        },
        {
          id: "banner4",
          imgUrl: require("@/assets/image/banner4.jpg"),
        },
      ],
      imgGaoUrl: require("@/assets/image/gao.png"),
    };
  },
};
</script>

<style  scoped>
* {
  box-sizing: border-box;
}

.bigBj {
  width: 100%;
  height: 1577px;
  /* background: url(../assets/image/banner.jpg) no-repeat; */
  background: url(../assets/image/bj.png) no-repeat;
  background-size: 100% 100%;
}
.centerImg {
  width: 1309px;
  margin: 0 auto;
  display: block;
}
.contentBox {
  width: 1200px;
  margin: 46px auto;
  background: #fff;
  box-sizing: border-box;
  padding: 10px 35px;
  box-shadow: 0px 0px 25px #c7b5b5;
  border-radius: 20px;
}
.title {
  position: relative;
  text-align: center;
  padding: 100px 0 40px;
}
.title h2 {
  padding-bottom: 10px;
  font-size: 38px;
  color: #333;
  font-weight: bold;
  line-height: 1.5em;
}
.title h3 {
  font-size: 20px;
  color: #d0031f;
  font-weight: bold;
}
.title2 {
  position: relative;
  text-align: center;
  padding: 20px 0 20px;
}
.title2 h3 {
  font-size: 20px;
  color: #d0031f;
  font-weight: bold;
}

.txt {
  padding: 40px 0;
}
.txt h1 {
  margin-top: 30px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
.txt p {
  line-height: 30px;
  padding-top: 12px;
  font-size: 16px;
  color: #4a4a4a;
  text-indent: 2em;
}
.left30 {
  padding: 0 0 0 30px;
  line-height: 2em;
  margin: 20px 0;
}
.content2 {
  display: flex;
  margin-top: 20px;
}
.centerTxt {
  text-align: center;
  line-height: 2em;
  margin-right: 2em;
}
.content2 p {
  line-height: 1.75em;
  margin-bottom: 20px;
  text-indent: 2em;
}
.rightTxt {
  text-align: right;
  padding-right: 100px;
}
</style>