var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bj",attrs:{"id":"zjbg"}},[_c('Title',{attrs:{"name":_vm.title}}),_c('div',{staticClass:"centerBox"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('RedTitle',{attrs:{"titleCont":_vm.redTitle}}),_vm._m(6)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("专题1：遗传学和基因组学")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("专题2：育种、社会科学和人类学")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("专题3：生理学")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("专题4：次级代谢")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("专题5：生物与非生物胁迫")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("专题6：采后技术与加工")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ztList"},[_c('div',{staticClass:"greyTxt"})])
}]

export { render, staticRenderFns }