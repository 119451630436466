var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bj",attrs:{"id":"eng_zjbg"}},[_c('Title',{attrs:{"name":_vm.title}}),_c('div',{staticClass:"centerBox"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('RedTitle',{attrs:{"titleCont":_vm.redTitle}}),_c('div',{staticClass:"ztList"})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("S1: Genetics and Genomics")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("S2: Breeding, Social Sciences and Humanities")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("S3: Physiology")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("S4: Secondary Metabolites")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("S5: Biotic and Abiotic stress")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb20"},[_c('div',{staticClass:"zhuanTi"},[_vm._v("S6: Postharvest Technologies and Processing")]),_c('div',{staticClass:"ztList"},[_c('span',{staticClass:"blackTxt"}),_c('span',{staticClass:"greyTxt"})])])
}]

export { render, staticRenderFns }