<template>
       
<div class="redTitle">{{titleCont}} >></div>
</template>

<script>
export default {
    props:['titleCont']
}
</script>

<style scoped>
.redTitle{font-size: 28px;color:#d0031f;margin:30px 0;}
</style>