<template>
  <div class="foot">

    <p> CopyRight © IX International Symposium on Rose Research and Cultivation - All rights reserved.</p>
   
     <a href="https://beian.miit.gov.cn/" target="_blank" style="color:#fff;">京ICP备09053977号-28</a> Email： Roses2025@163.com

  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.foot{width:100%;height:105px;text-align: center;padding-top: 30px;background:#292828;font-size:16px;color:#fff;line-height:2em;}

</style>